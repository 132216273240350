import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ResearchLandingTile from '../ResearchLandingTile';
import Container from '@material-ui/core/Container';
import './ResearchLandingHeader.scss';
import LoadMoreButton from '../../LoadMoreButton';

export default function ResearchLandingHeader({ landingData, contentData, basePath, location }) {
  const numberOfTilePerPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_PER_PAGE);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const urlParams = new URLSearchParams(location?.search);
  const researchLandingList = contentData?.map((researchData) => {
    const dateArray = researchData?.dateOfPublishing.split('T');
    const dateinTimestamp = new Date(dateArray[0].replace(/-/g, '/')).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...researchData,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp,
    };
  });

  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }

  const nowTime = new Date(Date.now()).getTime()
  const pastResearchList = researchLandingList.filter((reasearch)=>reasearch.dateinTimestamp<=nowTime && reasearch?.pageVisible)
  const researchs = pastResearchList.sort(comparePublishingDate);
  const [colsCount, setColsCount] = useState(numberOfTilePerPage);
  const tilesAddMore = () => {
    let nextColsCount =
      contentData?.length > colsCount + numberOfTilePerPage
        ? colsCount + numberOfTilePerPage
        : contentData?.length;
    setColsCount(nextColsCount);
  };
  useEffect(() => {
    setColsCount(numberOfTilePerPage);
  }, [location.href]);
  return (
    <>
      <Container maxWidth="xl" className="research-main-container">
        <Grid container className="reasearch-landing-cls">
          {/* Title Starts */}
          { researchs?.length ? (
            <>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <h1 locId="header" className="research-landing-title">
                {landingData?.header}
              </h1>
            </Grid>
            <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
          </Grid>
          {/* Title Ends */}
          <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
          <Grid
            container
            item
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
            className="research-container"
          >
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="research-card-container"
            >
              {researchs.slice(0,colsCount).map((research) => <ResearchLandingTile content={research} basePath={basePath} key={research.researchSlug}/>)}
            </Grid>
          </Grid>
          </>
          ) : (
            <></>
          )}
          <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
          {colsCount < researchs?.length ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <LoadMoreButton
                locId="loadMoreButton"
                handleLoadMore={tilesAddMore}
                loadMoreButton={landingData?.loadMoreButton}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
}
