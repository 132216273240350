import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import ResearchLanding from '../components/ResearchLanding';

export default function ResearchPage({ pageContext, data,location }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {

    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulResearchLandingPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext?.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <ResearchLanding
          landingData={data?.contentfulResearchLandingPage}
          contentData={data?.allContentfulResearch?.nodes}
          basePath={pageContext?.basePath}
          location={location}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query ResearchLandingPageQuery($contentful_id: String, $locale: String) {
    contentfulResearchLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      header
      entryTitle
      contentful_id
      node_locale
      capsulesBackgroundImage {
        description
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        description
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        description
        file {
          url
        }
      }
      loadMoreButton
      header
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
    }
    allContentfulResearch (filter: {node_locale: {eq: $locale}}){
      nodes {
        node_locale
        id
        entryTitle
        contentful_id
        researchSlug
        researchTitle
        thumbnailImage {
          description
          file {
            url
          }
        }
        dateOfPublishing
        resourceType
        pageVisible
        shortDescription {
          raw
        }
        thumbnailImage {
          description
          file {
            url
          }
        }
        document {
          file {
            url
          }
        }
        categoryList {
          categoryName
          contentful_id
          entryTitle
        }
        tagList {
          contentful_id
          entryTitle
          tagName
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
`;
